export default () => {
  try {
    return window.localStorage
  } catch (error) {
    return {
      getItem() {
        return null
      },
      setItem() {}
    }
  }
}
