import 'react-toastify/dist/ReactToastify.css';
import {toast} from 'react-toastify'




export default function Toast(type,msg) {   

    toast.configure()
    const alert = () =>{ 
        if("success" === type) {
            toast.success(msg,{position: toast.POSITION.TOP_CENTER,
            autoClose: 8000});    
        }else if("warning" === type){
            toast.warning(msg,{position: toast.POSITION.TOP_CENTER,
                autoClose: 8000});    
        }else{
            toast.error(msg,{position: toast.POSITION.TOP_CENTER,
                autoClose: 8000});    
        }   
      
    }
    return alert()

    
 }


