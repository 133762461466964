import api from './api'

const headers = { 
   'Content-Type': 'application/json',  
   'Authorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdXRob3JpemF0aW9uIjoidnMjMjAyMW1AbjBsJCJ9.eC1htBm2g6EqlgHOsEj04HZg6NTGFnfZhVAhqwOVD68', 
};

export const login = async ({ login = '', password = '' }) => {
   
   return await api.post('/login',{login, password},{headers})
   
}