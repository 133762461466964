import api from './api'

const headers = { 
   'Content-Type': 'application/json',
   'Authorization': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdXRob3JpemF0aW9uIjoidnMjMjAyMW1AbjBsJCJ9.eC1htBm2g6EqlgHOsEj04HZg6NTGFnfZhVAhqwOVD68', 
};

export const create = async (user) => {   
   return  api.post('/user',user,{headers})
   
}

export const confirmAccount = async (token) => {   
   return  api.post('/confirm_account',token,{headers,mode:'no-cors'})
   
}

export const updatePassword = async (token) => {   
   return  api.post('/passwd',token,{headers})
   
}