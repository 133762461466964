import React,{useState} from 'react'
import MenuBar from '../../components/MenuBar'
import Box from '@mui/material/Box';
import Capa from '../../assets/capaLivro.png'
import useMediaQuery from '@mui/material/useMediaQuery';
import AddToHomeScreen from '../../components/AddToHomeScreen'


export default function Home() {    
  const matches = useMediaQuery('(max-width:900px)');
  const [menuOpen, setMenuOpen] = useState(false)

  const handleMenuOpen = () => {   
    setMenuOpen(!menuOpen)
    
  }
       
        return (
          <>
                <MenuBar close={false} menuOpen={menuOpen} pageName={"Radiologia Diagnóstica Prática"}/>

                <Box
                sx={{
                    width:"99,5vw",
                    height: "100vh",                  
                    textAlign: "center",
                    justifyContent: "center",
                }}
                >     
                     
                <h3>Bem-vindo ao conteúdo complementar do livro Radiologia Diagnóstica Prática</h3>
               
                <img alt="capa" onClick={handleMenuOpen} style={{width:matches? '30vw':"10vw"}} src={Capa}></img> 

              
              
               {/*  <AddToHomeScreen/> */}
                </Box>
          </>

        )
   
}