import React from 'react';
import Logo from '../../assets/logo-manole-desk.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';

export default function PoweredBy(props) {
    const matches = useMediaQuery('(max-width:900px)');

    const useStyles = makeStyles({   
        PoweredBy:{
            position: props.logo? "" : 'fixed',
            bottom:10,                     
            textAlign: 'center',
            marginTop: '5vh',
            width:'100%',
        }
      });
      

    return(
       
        <div className={useStyles().PoweredBy}>
            {props.logo? (
                ''):( 
                <p>Powered by </p>      
                
            )}
            <p><img title="" alt="" style={{width: matches? '20%':'8vw'}}  src={Logo} /></p>
          </div>
   )

}