import React,{useState} from 'react'
import MenuBar from '../../components/MenuBar'
import { useParams } from "react-router-dom";
import Conteudos from '../../moks/conteudos'
import Box from '@mui/material/Box';
import Contents from '../../components/Contents'
import CardSesao from '../../components/CardContend'
import PoweredBy from '../../components/PoweredBy'
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Secao() {     
        const { idsecao } = useParams();
        const [mostraConteudo, setMostraConteudo] = useState(false);
        const [escolha, setEscolha] = useState('');
        const matches = useMediaQuery('(max-width:900px)');

        const contend = Conteudos.find(row => row.id === parseInt(idsecao));
        
             
        const handleClose = () => {        
            setMostraConteudo(false)
          };
        const handleOpenContend = () => {        
            setMostraConteudo(true)
        };
        
             
        return (
          <>
                <MenuBar close={handleClose} pageName={"Radiologia Diagnóstica Prática"} colorMenu={contend.secondaryColor} mostraConteudo={mostraConteudo} />
            {!mostraConteudo ? ( 
                    <> 
                <Box
                sx={{
                    width:"99,5vw",   
                    height:"95vh" ,             
                                 
                    backgroundColor: '#49C5B1',
                    textAlign: "center",
                    justifyContent: "center",
                }}
                >
                    <h4 style={{color:'white',marginTop:'10px', marginLeft:'10px', position:'absolute',dislay:"inline",marginBottom:'30px', fontSize:matches?15:30}}>{contend.titulo}</h4>
               <div style={{padding:matches?10:40}}>
                {contend.capitulos.map((row) => (
                   <>                   
                        <CardSesao props={row} setEscolha={setEscolha} handle={handleOpenContend} cor={contend.cor} />
                  
                    </>
                ))}
                </div>
                 <PoweredBy/>

                </Box>
                </>
                ) : (
                <Box
                    sx={{
                        width:"100%",                        
                        height: '100%', 
                        minHeight: '100vh',                 
                        backgroundColor: '#49C5B1', 
                        justifyContent: "start",
                        textAlign: "start",                        

                    
                    }}
                    >
                  
                    <Contents contend={escolha} prop={contend} close={handleClose} color={'#49C5B1'}  />
                
               
                
                
                </Box>

                )
                }

          </>

        )
   
}