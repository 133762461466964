import React from 'react'
import MenuBar from '../../components/MenuBar'
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';

import Tutorial from '../../components/Tutorial'

export default function TutorialPage() {    
  const matches = useMediaQuery('(max-width:900px)');
       
        return (
          <>
                <MenuBar close={false} pageName={"Radiologia Diagnóstica Prática"}/>

                <Box
                sx={{
                    width:"99,5vw",                   
                    height: "100vh",                  
                    textAlign: "center",
                    justifyContent: "center",
                }}
                >  
                 
                   <Tutorial  />
              
                </Box>
          </>

        )
   
}