import React,{useEffect} from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink  } from "react-router-dom";
import Secoes from '../../moks/conteudos'
import * as authService from '../../services/auth'
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShieldIcon from '@mui/icons-material/Shield';
import SlideshowIcon from '@mui/icons-material/Slideshow';
export default function MenuBar(props) {
  const [state, setState] = React.useState({
    left: false
  });
  const matches = useMediaQuery('(max-width:900px)');
    
    function exit(){
      authService.onLogout();
      document.location.reload(true);
    }

    const toggleDrawer = (anchor, open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
  
      setState({ ...state, [anchor]: open });
    };

    

    useEffect(() => {
      if(props.menuOpen){
        setState({ left:true });
      }
     
    }, [props.menuOpen])

    
    let navigate = useNavigate(); 

    const list = (anchor) => (
      <Box
        sx={{ width: matches? 250 : 500 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
      
        <List>     
          {Secoes.map((row) => (
            <ListItem key={row.id}
              onClick={()=>{
                navigate("/secao/"+row.id)               
              }}
               style={{color: row.cor}} 
               disablePadding
               >
                <ListItemButton>
                  <ListItemIcon>
                  <LibraryBooksIcon style={{color: row.cor,fontSize:30} } />
                  </ListItemIcon>  
                  <ListItemText primary={row.titulo} />
                </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>         
            <ListItem 
              key={'sobre'}
              onClick={()=>{
                navigate("/sobre")               
              }}            
              disablePadding>
              <ListItemButton>
                <ListItemIcon>
                 <AssignmentIcon /> 
                </ListItemIcon>
                <ListItemText primary={"Sobre"} />
              </ListItemButton>
            </ListItem>     

             <ListItem 
              key={'politica'}
              onClick={()=>{
                window.open("https://politicas.manole.com.br/")               
              }}            
              disablePadding>
              <ListItemButton>
                <ListItemIcon>
                 <ShieldIcon /> 
                </ListItemIcon>
                <ListItemText primary={"Política de privacidade"} />
              </ListItemButton>
            </ListItem>
            <ListItem 
              key={'tutorial'}
              onClick={()=>{
                navigate("/tutorial")               
              }}              
              disablePadding>
              <ListItemButton>
                <ListItemIcon>
                 <SlideshowIcon /> 
                </ListItemIcon>
                <ListItemText primary={"Tutorial"} />
              </ListItemButton>
            </ListItem>             
        </List>
      </Box>
    );
  
        
        return (
                <Box sx={{ flexGrow: 1 }}>
                   <AppBar position="static" sx={{ bgcolor: '#045BC8' }}> 
                 {/*  <AppBar position="static" > */}
                    <Toolbar >

                    {props.mostraConteudo? (
                        <IconButton
                            onClick={(e)=>{
                                e.preventDefault()
                                props.close()
                                }}
                          color="primary"
                          style={{ width:'30px', marginRight: '10px' }}
                          component="span">
                           <ArrowBackIcon style={{color: 'white', fontSize:'4vh'}} />
                       </IconButton>
                      ) :(                    
                      

                      <IconButton                     
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                      >
                        <MenuIcon 
                                id="basic-button"                             
                                aria-haspopup="true"                               
                                onClick={toggleDrawer('left', true)}
                        />
                        <Drawer
                        anchor={'left'}
                        open={state['left']}
                        onClose={toggleDrawer('left', false)}
                      >
                        {list('left')}
                      </Drawer>

                       
                      </IconButton>
                      )}
                      
                      <Typography  variant="h8" component="div" sx={{ flexGrow: 1, fontSize:matches? 15:30,marginLeft:2 }}>
                       {props.pageName}
                      </Typography>
                      <IconButton
                          component={NavLink}  
                          to={"/"}               
                          onClick={(e)=>{
                              e.preventDefault()
                              navigate('/');
                              }}
                          color="secondary"                         
                         >
                        <HomeIcon  style={{fontSize:'5vh', color:'white'}} /> 
                      </IconButton>
                      
                      <Button color="inherit" onClick={exit}>Sair</Button>
                    </Toolbar>
                  </AppBar>
                </Box>
        )


}