import React,{useEffect,useState} from 'react'
import {Container} from '@mui/material'
import { useParams } from "react-router-dom";
import {user} from '../../api/'
import BeatLoader from "react-spinners/BeatLoader";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Toast from '../../components/toast'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import logo from '../../assets/logoManole.png';
import { makeStyles } from '@mui/styles';
import { useForm } from "react-hook-form";
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment'
import ClipLoader from "react-spinners/ClipLoader";
import KeyIcon from '@mui/icons-material/Key';


const useStyles = makeStyles({
    btn: {
        marginTop: 50,
        inlineSize: '-webkit-fill-available',
        marginBottom: 20
    },
    body: {
        backgroundColor: 'rgb(8, 53, 118)',
        height: '100vh',
        width: '100vh'       
        
    }, 
    txt:{
        color: 'white',
    },  
    senhas:{
        color: 'white',
        marginTop: 'none',
        marginBottom: 'none',
        padding: 'none',
    },
    center:{
        textAlign: 'center',
        color: 'white',
    } 
  });


  function validaMaiscula(senha){
    let regexp = new RegExp(/^[A-Z]*$/);
    for(var i = 0; i < senha.length; i++){
        if(regexp.test(senha.charAt(i))){            
            return true
        }        
    }
    return false            
 } 
 function validaMin(senha){
    let regexp = new RegExp(/^[a-z]*$/);
    for(var i = 0; i < senha.length; i++){
        if(regexp.test(senha.charAt(i))){
           return true          
        }
    }
    return false            
 }
 function validaNumero(senha){
    let regexp2 = new RegExp(/[0-9]/);
    for(var i = 0; i < senha.length; i++){
        if(regexp2.test(senha.charAt(i))){            
            return true
        }
    }   
    return false         
 } 

 function validaEspecial(senha){
    let regexp2 = new RegExp(/\W|_/);
    for(var i = 0; i < senha.length; i++){
        if(regexp2.test(senha.charAt(i))){            
            return true
        }
    }     
    return false       
 }    

  const keyCaracterFalse = {
    color: 'red',
  }
  const KeyCaracterTrue = {
    color: '#00FB46',
  }
  const keyIniate ={
    color: 'white',
  }

const Form = (props)=>{
    const { register, handleSubmit } = useForm();
    const [loading , setLoading] = useState(false);    

    const classes = useStyles();
    const [minCaracter, setMinCaracter] = useState(keyIniate);
    const [minuscula, setMinuscula] = useState(keyIniate);
    const [maiuscula, setMaiuscula] = useState(keyIniate);
    const [numero, setNumero] = useState(keyIniate);
    const [caracteresEspecially, setCaracteresEspecially] = useState(keyIniate);


    function handleSubmitPasswd(data){
        console.log(data)
        if(data.usu_senha.length < 8){
            Toast('error','Informe uma senha com no mínimo 8 caracteres')
            return
        }
        if (!validaMaiscula(data.usu_senha)){
            Toast('error','a senha deve conter pelo menos uma letra maiscula')
            return
        }
        if (!validaMin(data.usu_senha)){
            Toast('error','a senha deve conter pelo menos uma letra minuscula')
            return
        }
        if (!validaNumero(data.usu_senha)){
            Toast('error','a senha deve conter pelo menos um numero')
            return
        }
        if (!validaEspecial(data.usu_senha)){
            Toast('error','a senha deve conter pelo caracter especial')
            return
        }

        if(data.usu_senha !== data.usu_senha2){
            Toast('error','a senha deve ser igual em ambos os campos')
            return
        }   
        
        setLoading(true)

        user.updatePassword(data).then(res => {            
            Toast('success',"Senha atualizada com sucesso!")  

            setTimeout(()=>{
                window.location.href = "/";
            },3000)

          }).catch((err) => {
              console.log(err) 
              Toast('error','Ocorreu um erro tente novamente.')
              setLoading(false)
        })   

        


    }

    function validSenha(senha){
        
        if(senha.length > 7){
            setMinCaracter(KeyCaracterTrue)
        }else{
            setMinCaracter(keyCaracterFalse)
        }
         
        validaMaiscula(senha)?  setMaiuscula(KeyCaracterTrue): setMaiuscula(keyCaracterFalse)
        validaMin(senha)? setMinuscula(KeyCaracterTrue): setMinuscula(keyCaracterFalse)
        validaNumero(senha)? setNumero(KeyCaracterTrue): setNumero(keyCaracterFalse)
        validaEspecial(senha)? setCaracteresEspecially(KeyCaracterTrue): setCaracteresEspecially(keyCaracterFalse)
    }

    return(

        <>
        <form onSubmit={handleSubmit(handleSubmitPasswd)}>
            <Input 
                type="hidden"
                name="usu_id"
                value={props.usu_id}
                {...register('usu_id', {
                    required: true                 
                                      
                   })}  
            />
            <InputLabel style={{color:"white"}} className={classes.txt} htmlFor="input-with-icon-adornment">
                Senha
            </InputLabel>
            <Input
                style={{color:"white", borderColor:"white"}}
               
                id="password"                        
                {...register('usu_senha', {
                     required: true,                    
                     onChange: (e)=> validSenha(e.target.value)                    
                    })}       
                fullWidth  
                type="password"
                startAdornment={
                    <InputAdornment position="start">
                    <KeyIcon style={{color:"white"}} />
                    </InputAdornment>
                }
            />

             <InputLabel style={{color:"white", marginTop: "10px"}} className={classes.txt} htmlFor="input-with-icon-adornment">
                Repita a senha
            </InputLabel>
            <Input
                style={{color:"white", borderColor:"white",marginBottom: "10px"}}
                id="repitaSenha"
                type="password"           
                {...register('usu_senha2', { required: true })}        
                fullWidth  
                startAdornment={
                    <InputAdornment position="start">
                    <KeyIcon style={{color:"white"}} />
                    </InputAdornment>
                }
            />
        {loading ? <ClipLoader  /> :
          <Button  className={classes.btn} type="submit" variant="contained">Cadastrar</Button>
    
          }
          </form> 
                <div className={classes.senhas} >
                <pf >A senha deve conter</pf>
                <br/>
                <pf style={minCaracter} >Mínimo 8 caracteres</pf>
                <br/>
                <pf  style={minuscula}>Mínimo 1 letra minúscula</pf>
                <br/>
                <pf  style={maiuscula}>Mínimo 1 letra maiúscula</pf>
                <br/>
                <pf  style={numero}>Mínimo 1 número</pf>
                <br/>
                <pf  style={caracteresEspecially}>Mínimo 1 caractere especial</pf>
                        
                </div>
        </>

    )
     
}  

export default function Confirm() {
    const { token } = useParams();   
    const [confirm, setConfirm] = useState(false);
    const [error, setError] = useState(false);
    const [userId,setUserId] = useState('')  
    const theme = createTheme();
    const classes = useStyles();

    async function onSubmit(token) {
      
        
           user.confirmAccount({token}).then(res => {
                console.log(res)
                setUserId(res.data)
                setConfirm(true)

               
              }).catch((err) => {
                  console.log(err)
                  setConfirm(true)
                  setError(true)
    
            })   
    }

    useEffect(() => {
        onSubmit(token)
    },[])

   return(

    <ThemeProvider theme={theme}>
        <Container component="main" className={classes.body}   maxWidth="100vw">
          <CssBaseline />
          <Box
            sx={{            
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >          
            <img align="center" alt="logo" src={logo} height='100vh'/>
          
            
            <Box noValidate sx={{ mt: 1 }}> 


    {!confirm ? (  
             <>
            <h1 className={classes.center}>Confirmando conta token</h1>   
            <BeatLoader/>
            </>
    ) : 
        error ? (
            <h1>Token inválido</h1>
        ) : (
            <>
            <h1 className={classes.center}>Conta validada</h1>
            <p className={classes.txt} >Cadastre uma senha para acesso</p>
            {<Form  usu_id={userId.userId}   />}
            </>
        )
    }
    </Box>
          </Box>
        
        </Container>
      </ThemeProvider>
    )
    
    
   
}