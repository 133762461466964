import React,{useState} from 'react';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import BookmarkAddedRoundedIcon from '@mui/icons-material/BookmarkAddedRounded';
import Input from '@mui/material/Input';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import InputMask from "react-input-mask";
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { useForm } from "react-hook-form";
import Toast from '../../components/toast'
import ClipLoader from "react-spinners/ClipLoader";
import Switch from '@mui/material/Switch';
import Link from '@mui/material/Link';
import Capa from '../../assets/capaLivro-removebg-preview.png'
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import PoweredBy from '../../components/PoweredBy'
import {user} from '../../api/'


const useStyles = makeStyles({
    btn: {
        marginTop: 40,
        inlineSize: '-webkit-fill-available',     
    },
    header:{
        textAlign: 'center',
    },
    label:{
        marginTop: 20,
        fontColor:'white'
    },
    capa:{
        width: '80%',
    }
  });

function Copyright(props) {
    return (
        <PoweredBy logo={'logo'} />
     
       );
  }

function validaNascimento(date) {
    try{
        let data = date.split("/")

        let dia = data[0]
        let mes = data[1]
        let ano = data[2]
    
        if(dia < 1 || dia > 31) {
            return false
        }
        if(mes < 1 || mes > 12) {
            return false
        }
    
        let anoAtual = new Date().getFullYear()    
        let age = anoAtual - ano
        
        if(age < 17 || age >90) {
            return false
        } 
    
        return true
    }catch (e) {
        return false
    }
    
}  

export default function Cadastro() {    
    const { register, handleSubmit } = useForm();
    const [loading , setLoading] = useState(false);
    const matches = useMediaQuery('(max-width:900px)');

    const classes = useStyles();

   async function onSubmit(data) {
       
    var nome = data.usu_nome.trim().split(" ")

    if(!data.aceite){
        Toast('error','Leia e aceite os termos de uso')
        return
    }

    if(nome.length < 2 ){
        Toast('error','informe o nome completo')
        return
    }
    if(!data.usu_cpf ){
        Toast('error','informe o cpf')
        return
    }

    if(!validaNascimento(data.usu_data_nasc)){
        Toast('error','informe uma data de nascimento válida')
        return
    }
   
    data.usu_nome = nome[0]
    nome.shift()
    data.usu_nome_sobrenome = nome.toString().replace(',',' ')

    data.usu_email  = data.usu_email.toLowerCase()


   
    setLoading(true)

    try{
       user.create(data).then(res => {
            console.log(res)  
            Toast('success',"Cadastro realizado com sucesso, aguarde o recebimento do e-mail com os procedimentos para o acesso.")
            setTimeout(()=>{
                    window.location.href = "/";
            },5000)
            setTimeout(()=>{
                setLoading(false)
            },5000)
          }).catch((err) => {
              if(err.response.status === 406){
                    Toast('error','Voucher invalido!')
                    setTimeout(()=>{
                        setLoading(false)
                    },5000)
                   
            }

        }) 
    }catch(e){  
        Toast('error','Erro ao realizar o cadastro')
        setTimeout(()=>{
            setLoading(false)
        },5000)
    }
    
 
}
   return(
        
      
        <Container style={{backgroundColor:'#49C5B1',marginTop:0, textAlign: '-webkit-center'}}  maxWidth="100vw">
         
            <Box
                sx={{
                backgroundColor: '#49C5B1', 
                marginTop: 0,
                height: '100%',
                minHeight: '100vh',               
                width: matches? '100%' : '50%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                }}
            >           


                <h2 className={classes.header}>
                Para acessar o conteúdo, é necessário cadastrar-se
                pelo formulário abaixo e informar o voucher fornecido no livro.
                <p>Bons estudos!</p> 
                </h2>

                <Grid container spacing={2}>
                    <Grid item style={{alignSelf:'center'}} xs={12} md={6} >    
                        <img className={classes.capa} src={Capa} alt="" />    
                    </Grid>
                    <Grid item xs={12} md={6}>    
                     <Box style={{textAlign: 'initial', color:'white'}}noValidate sx={{ mt: 1 }}>

                
                    <form onSubmit={handleSubmit(onSubmit)}>
                  
                            <InputLabel className={classes.label} htmlFor="input-with-icon-adornment">
                                Nome completo
                            </InputLabel>
                            <Input
                                id="usu_nome"
                                type="text"           
                                {...register('usu_nome', { required: true })}        
                                fullWidth  
                                startAdornment={
                                    <InputAdornment position="start">
                                    <AccountCircle />
                                    </InputAdornment>
                                }
                                />

                            <InputLabel className={classes.label} htmlFor="input-with-icon-adornment">
                                CPF
                            </InputLabel>
                                        
                            <InputMask            
                                mask="999.999.999-99"          
                                id="usu_cpf"
                                {...register('usu_cpf', { required: true })}
                            > 
                                {() => <Input
                                    {...register('usu_cpf', { required: true })}
                                    fullWidth 
                                    startAdornment={
                                        <InputAdornment position="start">
                                        <AspectRatioIcon />
                                        </InputAdornment>
                                    }
                                />}
                            
                            </InputMask  >

                            <InputLabel  className={classes.label} htmlFor="input-with-icon-adornment">
                                E-mail
                            </InputLabel>
                                        
                            <Input
                                id="usu_email"             
                                ref={register}
                                type="email"
                                {...register('usu_email', { required: true })}
                                fullWidth
                                startAdornment={
                                    <InputAdornment position="start">
                                    <AlternateEmailIcon />
                                    </InputAdornment>
                                }
                            />
                            
                            <InputLabel className={classes.label} htmlFor="input-with-icon-adornment">
                                Data de Nascimento
                            </InputLabel>
                                        
                            <InputMask
                                id="usu_data_nasc"
                                mask="99/99/9999"
                                {...register('usu_data_nasc', { required: true })}             
                            >
                                {() => <Input
                                {...register('usu_data_nasc', { required: true })} 
                                fullWidth
                                    startAdornment={
                                        <InputAdornment position="start">
                                        <AspectRatioIcon />
                                        </InputAdornment>
                                    }
                                />}
                            </InputMask>

                            <InputLabel className={classes.label} htmlFor="input-with-icon-adornment">
                                Telefone
                                </InputLabel>
                                        
                            <InputMask
                                id="usu_telefone"
                                mask="(99)-9999-9999*"
                                {...register('usu_telefone', { required: true })}             
                            >
                                {() => <Input
                                {...register('usu_telefone', { required: true })} 
                                fullWidth
                                    startAdornment={
                                        <InputAdornment position="start">
                                        <ContactPhoneIcon />
                                        </InputAdornment>
                                    }
                                />}
                            </InputMask>

                            <InputLabel className={classes.label} htmlFor="input-with-icon-adornment">
                                Voucher
                            </InputLabel>
                                        
                            <Input
                                id="voucher"             
                                ref={register}           
                                {...register('voucher', { required: true })}
                                fullWidth
                                startAdornment={
                                    <InputAdornment position="start">
                                    <BookmarkAddedRoundedIcon />
                                    </InputAdornment>
                                }
                            />


                                <FormControlLabel
                                     control={<Switch id="newsletter"             
                                                ref={register}           
                                                {...register('newsletter')} />}
                                     label="Desejo receber notícias e promoções"
                                     />      
                                <FormControlLabel control={<Switch id="aceite" ref={register}           
                                                {...register('aceite')}  />}
                                    
                                    label={(<Link target="_blank" href="https://politicas.manole.com.br/" underline="none">
                                                                                                        Eu li e aceito os Termos de Uso e Politicas de Privacidade
                                                                                                    </Link>) } />      

                          



                            {loading ? <ClipLoader  /> : 
                            <Button  className={classes.btn} style={{marginTop:30,marginBottom:30}} type="submit" variant="contained">Cadastrar</Button>
                            }
                            </form>

        </Box>
        </Grid>
        </Grid>
           <Copyright sx={{ mt: 8, mb: 4 }} />
        </Box>
        
        
</Container>

                    
          
          
      
    )
    
}