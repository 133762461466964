import React from 'react';
import Routes from './routes'

function App() {
  return (
    <div className="App">     
    
          <Routes/>
      
    </div>
  );
}

export default App;
