import React from 'react'
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


export default function Paginacao(props) {


        const handleChange = (value) => {
          console.log(value)
          props.setLoaded(false)
            props.setItem(value);
           
        };

        const teste = (value)   => {
            if(value >= 5 && props.page < 5){
             return 'none'
            }else if(value < 5 && props.page > 4){
                return 'none'
            }
        }
        
    return(
       
        <Stack style={{display: "inline-table",justifyContent:'center', marginTop:30, width:'100%'}} direction="row" >

                <IconButton 
                    size="large"
                    style={{color:'white',borderRadius: "50%", height:40,display:props.page === 1 ? 'none' : 'inline-flex'}}
                    disabled={props.page === 1? true: false}
                    onClick={()=>{handleChange(props.page-1)}}
                    >
                    <ArrowBackIosIcon/>
                 </IconButton>

            {props.contend.length < 5? (
                     <>
                    {props.contend.map((item, index) =>
                        (
                        <IconButton  size="medium" style={{backgroundColor: props.page === index+1 ? 'blue': '', color:'white',borderRadius: "50%", height:40,}} value={index+1} onClick={()=>{handleChange(index+1)}} aria-label="delete">
                            {item.label}
                        </IconButton>
                        )
                    )}
                    </>

            ): (
                <>
                {props.page >4? (
                      <IconButton onClick={()=>{handleChange(4)}} size="large" style={{color:'white',borderRadius: "50%", height:40}}>
                      ...
                    </IconButton>
                  ): ''}
                {props.contend.map((item, index) =>
                    (
                    <IconButton  size="medium" style={{backgroundColor: props.page === index+1 ? 'blue': '', color:'white',borderRadius: "50%",minHeight:30,maxHeight:30, display: teste(index+1)}} value={index+1} onClick={()=>{handleChange(index+1)}} aria-label="delete">
                        {item.label}
                    </IconButton>
                    )
                )}

                    {props.page <5? (
                      <IconButton onClick={()=>{handleChange(5)}} size="large" style={{color:'white',borderRadius: "50%", height:40}}>
                      ...
                    </IconButton>
                  ): ''}



                </> 
            )}
             <IconButton
                 size="large"
                 style={{color:'white',borderRadius: "50%", height:40,display:props.page === props.contend.length ? 'none' : 'inline-flex'}}
                 disabled={props.page === props.contend.length? true: false}
                 onClick={()=>{handleChange(props.page+1)}}
                 >
                <ArrowForwardIosIcon/>
            </IconButton>
           
          
        </Stack>
   )

}

