import React from 'react';
import {BrowserRouter,Route,Routes,Navigate } from 'react-router-dom'
import * as authService from '../services/auth'

import Home from '../pages/Home'
import Login from '../pages/Login'
import Cadastro from '../pages/Cadastro'
import Confirm from '../pages/Confirm'
import Secao from '../pages/Secao'
import Sobre from '../pages/Sobre'
import Tutorial from '../pages/Tutorial'


function CustomRoute({page}) {

  if (!authService.getAccessToken()) {
    return <Navigate  to="/login" />
  }

  if(!authService.getValid()){
    console.log('invalid')
    return <Navigate  to="/login" />
  }

  return page;
}

export default function Router(){

    return(
        <BrowserRouter>
          <Routes>
            <Route  isPrivate exact path="/" element={<CustomRoute page={<Home/>} />} />
            <Route  path="/login" exact element={<Login/>}/>   
            <Route isPrivate path="/sobre" exact element={<Sobre/>}/>              
            <Route isPrivate path="/cadastro" exact element={<Cadastro/>}/> 
            <Route  path="/confirm/:token" exact element={<Confirm/>}/> 
            <Route  isPrivate path="/secao/:idsecao" exact element={<CustomRoute page={<Secao/>}/>}/> 
            <Route  isPrivate path="/tutorial" exact element={<CustomRoute page={<Tutorial/>}/>}/> 

            
          </Routes>
        </BrowserRouter>
    )
}