import React from 'react'
import MenuBar from '../../components/MenuBar'
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Paper from '@mui/material/Paper';
import FotoLuciana from '../../assets/autores/lucianazattar.jpeg'
import FotoExemplo from '../../assets/autores/male.png'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = (matches) => makeStyles({
    card: {
        width: '80%' ,
        display: matches? '': 'inline-flex' ,
        marginTop:'10px'
         
      },
      media: {       
        height: '10%', 
        width: '10%',     
      },
  });

export default function Sobre() {    
  const matches = useMediaQuery('(max-width:900px)');
  const classes = useStyles(matches);

/*   const useStyles = makeStyles({
    card: {
        width: '100%' ,
        display: matches? '': 'inline-flex' ,    
        textAlign:'-webkit-center'
      },
      media: {       
        height: '10%', 
        display: matches? '-webkit-center':'',
        width: '10%',     
      },
    }); */
        return (
          <>
                <MenuBar close={false} pageName={"Radiologia Diagnóstica Prática"}/>

                <Box
                sx={{                  
                    width:"99,5vw",                    
                    minHeight: "100vh",                            
                    textAlign: "center",
                    justifyContent: "center",
                    backgroundColor: '#49C5B1',
                }}
                > 
                    <Box
                    sx={{
                        marginTop: matches? "3vh" : "10vh", 
                        display: "inline-flex",  
                        width: matches? "92vw" : "50vw",                                       
                        textAlign: "center",
                        justifyContent: "center",
                    }}
                    > 
                    <Paper elevation={2} style={{padding: "5px"}}  >
                    <h1>SINOPSE</h1>
                    <p>O livro contempla quase todas as especialidades radiológicas, entre as quais se destacam sistema nervoso central, cabeça e pescoço, tórax, abdome e pelve, sistema musculoesquelético, mama, obstetrícia, ultrassonografia geral e física radiológica. A intenção foi elaborar um material didático, por isso a obra é rica em figuras, conceitos práticos e dicas úteis para facilitar a formação de radiologistas e servir como ferramenta de atualização para radiologistas gerais em sua atuação profissional.</p>
                    </Paper>
                    </Box>

                    <Box
                    sx={{
                        display: "inline-flex",  
                        width: matches? "90vw" : "60vw",  
                        marginTop: "5vh",                                   
                        textAlign: "center",
                        justifyContent: "center",
                        marginBottom: "5vh",
                    }}
                    > 
                    <Paper elevation={2} style={{padding: "5px"}}  >
                    <h1>SOBRE O AUTOR</h1>

                    <Card className={classes.card} style={{textAlign: '-webkit-center', margin:20}}>
                        <CardMedia
                            component="img"
                            className={classes.media}
                            alt="Luciana Zattar"
                            style={{width: matches?  "100px": '200px',}}                                             
                            image={FotoLuciana}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Luciana Zattar
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            Médica graduada pela Universidade Federal de Santa Catarina. Médica Especialista em Radiologia e diagnóstico por Imagem no Hospital Sírio-Libanês. Especialização em Radiologia do Sistema Musculoesquelético no Hospital Sírio-Libanês.
                            </Typography>
                        </CardContent>                       
                    </Card>

                    
                    <Card className={classes.card} style={{textAlign: '-webkit-center',margin:20  }}>
                        <CardMedia
                            component="img"
                            className={classes.media}
                            alt="Públio Cesar Cavalcante Viana"                            
                            resizeMode='contain'
                            style={{width: "200px",marginTop: "10px"}}  
                            image={FotoExemplo}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Públio Cesar Cavalcante Viana
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                            Coordenador Médico da Divisão de Radiologia Geniturinária do Instituto de Radiologia do Hospital das Clínicas da Faculdade de Medicina da Universidade de São Paulo (InRad-HCFMUSP). Coordenador Médico do Setor de Tomografia Computadorizada do Hospital Sírio-Libanês.
                            </Typography>
                        </CardContent>                       
                    </Card>

                    <Card className={classes.card} style={{textAlign: '-webkit-center',margin:20  }}>
                        <CardMedia
                            component="img"
                            className={classes.media}
                            alt="Giovanni Guido Cerri"                            
                            resizeMode='contain'
                            style={{width: "200px",marginTop: "10px"}} 
                            image={FotoExemplo}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Giovanni Guido Cerri
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Diretor do Centro de Diagnóstico por Imagem do Hospital Sírio-Libanês. Professor Titular da Disciplina de Radiologia da Faculdade de Medicina da Universidade de São Paulo (FMUSP).
                            </Typography>
                        </CardContent>                       
                    </Card>
                 
                
                    </Paper>
                       </Box>
                   
                
                </Box>
          </>

        )
   
}