import React, { Component } from 'react'
import showAddToHomeScreen, { hideMessage } from './showAddToHomeScreen'
import iosShare from '../../icons/svg/ios_share.svg'
import iosAddHome from '../../icons/svg/ios_add_home.svg'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = (theme) => ({
  root: {
    display: 'flex',
    position: 'absolute',
    background: 'rgba(255,255,255,.92)',
    zIndex: 9999,
    bottom: 18,
    left: 0,
    right: 0,
    padding: '8px 4px',
    borderRadius: 8,
    maxWidth: '95%',
    margin: 'auto',
    filter: 'drop-shadow(0 0 0.75rem rgba(0,0,0,.2))',
    alignItems: 'center',
  },
  iosShare: {
    display: 'inline-block',
    '&::before': {
      content: `url(${iosShare})`,
      marginLeft: '0.4em',
      marginRight: '0.4em',
    },
  },
  iosAddHome: {
    display: 'block',
    padding: 8,
  },
  message: {
    color: '#000',
    fontSize: '.9em',
    flex: '1',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 4,
    '-webkit-touch-callout': 'none',
  },
  arrow: {
    width: 0,
    height: 0,
    border: '10px solid transparent',
    borderTopColor: 'rgba(255,255,255,.92)',
    position: 'absolute',
    left: 0,
    right: 0,
    margin: 'auto',
    bottom: -10 * 2,
  },
})

class AddToHomeScreen extends Component {
  state = {
    show: true,
  }
  handleClick = () => {
    hideMessage() && this.setState({ show: true })
  }
  render() {
    const { classes } = this.props
    return this.state.show && showAddToHomeScreen() ? (
      <div className={classes.root} onClick={this.handleClick}>
        <img
          src={iosAddHome}
          className={classes.iosAddHome}
          aria-hidden={true}
          alt={'instale App em sua tela inicial'}
        />
        <div className={classes.message}>
          Instale App em sua tela inicial. Toque em <i className={classes.iosShare} />e
          toque em Tela de Início.
        </div>
        <div className={classes.arrow} />
      </div>
    ) : null
  }
}

export default withStyles(styles)(AddToHomeScreen)
