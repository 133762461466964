import React from 'react'
import Carousel from 'react-material-ui-carousel'
//import useMediaQuery from '@mui/material/useMediaQuery';
import Img1 from '../../assets/tutorial/page01.png'
import Img2 from '../../assets/tutorial/page02.png'
import Img3 from '../../assets/tutorial/page03img.png'
import Img5 from '../../assets/tutorial/page05.png'


export default function Tutorial() {   
    //const matches = useMediaQuery('(max-width:900px)');

    var items = [
        {
            name: "menu",
            text: "No menu lateral, você acessa as seções de seu interesse",
            url: Img1,
        },
        {
            name: "sessao",
            text: "Cada seção contém os capítulos que estão disponíveis",
            url: Img2,
        },
        {
            name: "conteudo1",
            text: "Após acessar um capítulo, navegue pelos conteúdos por meio do menu inferior",
            url: Img3,
        },
        {
            name: "imagem",
            text: "Clique na imagem para ampliar",
            url: Img5,
        }
    ]

    function Item(props)
    {
        return (
            <>
               <img alt='teste' style={{height:'70vh'}} src={props.item.url} />
               <p>{props.item.text}</p>
            </>
         
        )
    }
  
       
        return (
          <>
            <Carousel sx={{paddingTop:3}} navButtonsAlwaysVisible='true' cycleNavigation='false'  autoPlay='false' animation='fade'>
            {
                items.map( (item, i) => <Item key={i} item={item} /> )
            }
             </Carousel>
          </>

        )
   
}