import React,{useState} from 'react'
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
//import Pagination from '@mui/material/Pagination';
//import Stack from '@mui/material/Stack';
import { CardMedia } from '@mui/material';
import BeatLoader from "react-spinners/BeatLoader";
import { CardActionArea } from '@mui/material';
import Paginacao from '../Pagination';

const useStyles = makeStyles({   
    legenda:{
        textAlign: 'center',
        color: 'white',
        padding: '10px',
    }
  });
  


export default function Contents(props) {   
    const matches = useMediaQuery('(max-width:900px)');
    const [item , setItem] = useState(1);
    const classes = useStyles();   

   /*  const handleChange = (event, value) => {
        setLoaded(false)
        setItem(value);
    }; */
    
    const [loaded, setLoaded] = React.useState(false);




    function handleImageLoad() {
        setLoaded(true);
      }
  
    return (
        <>
          <Box
                sx={{
                    width: matches? "100%": "100%",                                   
                    justifyContent: "center",
                    height: matches? "auto" : "auto", 
                    minheight: '100vh',                  
                    textAlign: "center",
                                  
                }}
                >  
               
                {props.contend[item -1].tipo === 'video' ? (
                       <>   
                    <iframe
                    style={{height: matches ?'50vh' : '40vh',marginTop: matches ? "0px" : '20px',  }}            
                    title={props.contend[item -1].legenda}
                    align-items="start"                
                    id='playvideo'
                    width="100%"                  
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                    src={props.contend[item -1].conteudo}
                    ></iframe>
                    <div dangerouslySetInnerHTML={{__html: props.contend[item -1].legenda}} className={classes.legenda}></div>
                    </>  
                   
                ) : (
                    <>
                   {!loaded? (
                       <div  style={{paddingTop: 50}}>                         
                         <BeatLoader style={{position: 'absolute', paddingTop: 50}}/>
                       </div>
                   ): ''}
                 <CardActionArea>
                    <CardMedia                         
                        component="img" 
                        onLoad={handleImageLoad}
                        onClick={()=> window.open(props.contend[item -1].conteudo, "_blank")}
                        style={{marginTop: "20px",display: loaded? "inline-table":'none',  objectFit: 'fill',width: matches ?'70%' : '40%',height: matches ? '30vh' : '50vh',justifyContent: 'center'}}            
                        image={props.contend[item -1].conteudo}
                        title={'Clique para abrir'}
                    ></CardMedia>
                </CardActionArea>
                 {/*    <div className={classes.legenda}>{props.contend[item -1].legenda}</div> */}
                 <div dangerouslySetInnerHTML={{__html: props.contend[item -1].legenda}} className={classes.legenda}></div>
                  </>
                    
            )}

                {props.contend.length > 1 ? (
                   /*  <Stack style={{display: "inline-table", marginTop:20,color:'white'}} spacing={2}>                      
                        <Pagination aria-label={'A'} color={'primary'} count={props.contend.length} style={{color:'white' }} page={item} setItemAriaLabel={"A"}  onChange={handleChange}  />                    
                    </Stack>
                */
                     <Paginacao  contend={props.contend} page={item} setItem={setItem}  setLoaded={setLoaded}/>

                ): ''} 

            </Box>
         
      </>
      

    )

}