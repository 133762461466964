import React from 'react'
import { makeStyles } from '@mui/styles';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import useMediaQuery from '@mui/material/useMediaQuery';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';


/* 
function generateColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    
    return color;
    
  } */


export default function CardContente(props) {   
    
    const matches = useMediaQuery('(max-width:900px)');

    
    const useStyles = makeStyles({   
      card:{
          textAlign: 'center',       
          color: 'black',
          padding: '15px',
          width: matches? "70%": "50%",
          display: 'inline-table',
          marginTop: '5vh',
          
      },
      book:{
          fontSize: 'large',
          marginTop: '-40',
          float: 'right',  
          display: 'flex',
      },
      actions:{
          fontSize:30,
          justifyContent: 'space-between',
          padding: '0',
      }
    });

    const classes = useStyles(); 

    const handle = () =>{
      console.log(props)
      props.setEscolha(props.props.conteudos)
      props.handle()
    }
    
    return (
        <>       
     

      <Card elevation={8} style={{backgroundColor: '',height: '3vh',padding:'8px'}}  className={classes.card} sx={{ minWidth: 150 }}>           
            <CardActions 
              onClick={(e)=>{
                   e.preventDefault();
                   handle()
                  }}
              className={classes.actions} style={{padding:0,fontSize:20 }}>
            <LibraryBooksIcon style={{color: props.cor,fontSize:30} } />{props.props.nome} <BookmarkIcon style={{color: props.cor,marginTop:"-30",fontSize:30 }} fontSize="large" className={classes.Book} />
            </CardActions>
        </Card> 
      </>
    )

}