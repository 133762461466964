import { CookieStorage } from 'cookie-storage'
import jwtDecode from 'jwt-decode'
const moment = require('moment')

const TOKEN = 'token'

export const makeCookieStorage = () => {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()
    const expires = new Date(year + 200, month, day)
    return new CookieStorage({
      expires
    })
  }

const cookieStorage = makeCookieStorage()
const getStorageAuthKey = key => (key)
export const getAccessToken = () => cookieStorage.getItem(getStorageAuthKey(TOKEN)) || null

export const decodedToken = () => (getAccessToken() && jwtDecode(getAccessToken())) || {}

export const getUser = () => decodedToken(getAccessToken()) || ''

export const getValid = () =>{
 
 const timeExpired =  decodedToken(getAccessToken()).exp || 0
 const timeNow = moment().unix()


  if(timeExpired < timeNow){
    onLogout()
    return false
  }

  return true


} 


export const onLogout = () => {   
    cookieStorage.clear()
}

export const updateToken = token => {
    cookieStorage.setItem(TOKEN, token)
}

export const login = ({ token}) => {

   
    cookieStorage.setItem(getStorageAuthKey(TOKEN), token)

    const user = decodedToken()
    console.log(user)

    const contend = user.conteudos.find(row => row.conteudo_id === 2);
 
    if(!contend){
      cookieStorage.clear()     
    }
  
  
}  