import React,{useState} from 'react'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ClipLoader from "react-spinners/ClipLoader";
import Toast from '../../components/toast'
import {auth} from '../../api/'
import * as authService from '../../services/auth'
import {Navigate } from 'react-router-dom'
import Capa from '../../assets/capaLivro.png'
import useMediaQuery from '@mui/material/useMediaQuery';


function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        Termos de uso e Política de privacidade <br/>
        <Link target="_blank" color="inherit" href="https://politicas.manole.com.br/">
        https://politicas.manole.com.br/
        </Link>
       
      </Typography>
    );
  }

  
  const theme = createTheme();

  export default function SignIn() {
    const [login,setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [loading , setLoading] = useState(false);  
    const matches = useMediaQuery('(max-width:900px)');

    const handleSubmit = (event) => {
      setLoading(true)
      event.preventDefault();     
      console.log(login, password)

      if(!login || !password) {
        Toast('error','Informe o login e senha')
        setLoading(false) 
        return
      }

      auth.login({login, password}).then(res => {  
       const {data:token } = res           
       authService.login({token:token.token})
       setLoading(false)
       return (
        <Navigate 
          to={'/'}
        />
      )  

      }).catch((err) => {
          console.log(err)
          Toast('error','Ocorreu um erro tente novamente')
          setLoading(false) 
         
    }) 
    };

    if (authService.getAccessToken()) {
      return (
        <Navigate 
          to={'/tutorial'}
        />
      )
    }
  
    return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,             
              display: 'flex',              
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img alt="capa" style={{width:matches? '30vw':"10vw"}} src={Capa}></img>
            
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                onChange={(e)=>setLogin(e.target.value)}
                fullWidth
                value={login}
                id="email"
                label="E-mail"
                name="email"                
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                onChange={(e)=>setPassword(e.target.value)}
                name="password"
                value={password}
                label="Senha"
                type="password"
                id="password"               
              /> 
              {loading ? <ClipLoader  /> :       
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Entrar
                </Button>
               }
              <Grid container>
                <Grid item xs>
                  <Link href="https://atendimento.manole.com.br/recuperar-senha/manole.php?q=conteudoPwa"  variant="body2">
                    Esqueceu sua senha?
                  </Link> 
                </Grid>
                <Grid item>
                  <Link href="/cadastro" variant="body2">
                    {"Cadastre-se"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </ThemeProvider>
    );
  }