const url = 'https://residentes.s3.amazonaws.com/radiologia_pratica/'
const secoes = [
    {
        "id": 2,
        "titulo": "Seção 2 – Tórax",
        "cor": "#00977E",
        "secondaryColor":'#257A6C',
        "capitulos": [
            {
                "id": 1,
                "nome": "Capítulo 7",              
                "conteudos": [
                    {
                        "tipo": "video",
                        "label": "1",
                        "conteudo": "https://player.vimeo.com/video/700839579",
                        "legenda": `Paciente com discinesia ciliar. Tomografia de tórax evidenciando bronquiectasias
                                     cilíndricas, císticas e varicosas predominando nos campos médios e inferiores,
                                     associadas a micronódulos centrolobulares e imagens de árvore "em brotamento".`
                    }                  

                ]
            },
            {
                "id": 2,
                "nome": "Capítulo 8",
                "conteudos": [
                    {
                        "tipo": "video",
                        "label": "1",
                        "conteudo": "https://player.vimeo.com/video/700839628",
                        "legenda": `Opacidades "em vidro fosco" periféricas bilaterais, padrão típico para Covid-19.`
                    },
                    {
                        "tipo": "video",
                        "label": "2",
                        "conteudo": "https://player.vimeo.com/video/700839665",
                        "legenda": `Opacidades "em vidro fosco" periféricas multifocais associadas a consolidações, padrão típico para Covid-19.`
                    },
                    {
                        "tipo": "video",
                        "label": "3",
                        "conteudo": "https://player.vimeo.com/video/700839703",
                        "legenda": "Pneumomediastino, pneumoperitônio e extenso enfisema subcutâneo em paciente sob ventilação mecânica por Covid-19 grave."
                    },
                ]
            
            },            
            {
                "id": 3,
                "nome": "Capítulo 16",
                "conteudos": [
                    {
                        "tipo": "video",
                        "label": "1",
                        "conteudo": "https://player.vimeo.com/video/700839743",
                        "legenda": "Sequências cine."
                    },
                    {
                        "tipo": "video",
                        "label": "2",
                        "conteudo": "https://player.vimeo.com/video/700839779",
                        "legenda": "Sequências de perfusão miocárdica sob estresse farmacológico e em repouso, demonstrando defeito perfusional subendocárdico na parede inferior/inferosseptal sob estresse, ausente ao repouso, sugerindo isquemia miocárdica. À direita, observam-se as imagens de realce tardio, que não evidenciam realce sugestivo de necrose."
                    },
                ]
            }
        ]
    },
    {
        "id":3,
        "titulo": "Seção 3 – Medicina interna – gastrointestinal",
        "cor": "#F386A7",
        "secondaryColor":'#C76D88',
        "capitulos": [          
            {
               "id": 5,
               "nome": "Capítulo 9",
               "conteudos":[
                {
                    "tipo": "video",
                    "label": "1A",
                    "conteudo": "https://player.vimeo.com/video/700839850",
                    "legenda": "Adenocarcinoma de pâncreas inoperável. Lesão sólida hipovascular na cabeça pancreática envolvendo circunferencialmente a artéria mesentérica superior."
                },
                {
                    "tipo": "imagem",
                    "label": "1B",
                    "conteudo": url + '9786555767834-S03C009QR001A.png',
                    "legenda": "Adenocarcinoma de pâncreas inoperável. Lesão sólida hipovascular na cabeça pancreática envolvendo circunferencialmente a artéria mesentérica superior."
                },
                {
                    "tipo": "video",
                    "label": "2A",
                    "conteudo": "https://player.vimeo.com/video/700839887",
                    "legenda": "Adenocarcinoma de pâncreas <i>borderline</i>. Lesão sólida hipovascular na cabeça pancreática, sem contato com artéria hepática comum ou com tronco celíaco, porém com contato < 180° com a veia mesentérica superior e junção esplenomesentérica."
                },
                {
                    "tipo": "imagem",
                    "label": "2B",
                    "conteudo": url + '9786555767834-S03C009QR002A.png',
                    "legenda": "Adenocarcinoma de pâncreas <i>borderline</i>. Lesão sólida hipovascular na cabeça pancreática, sem contato com artéria hepática comum ou com tronco celíaco, porém com contato < 180° com a veia mesentérica superior e junção esplenomesentérica."
                },
               ]
            },
            {
               "id":6,
               "nome": "Capítulo 10",
               "conteudos":[
                   { 
                    "tipo":"imagem",
                    "label": "1",
                    "conteudo": url+"9786555767834-S03C010QR001 final.png",
                    "legenda": `Neoplasias mucinosas papilares intraductais com sinais de alarme. A e B: imagens de tomografia computadorizada axial e reconstrução MPR demonstram lesão cística na cabeça pancreática com nódulo sólido mural com realce pelo meio de contraste (setas amarelas); C e D: imagens axial T2 e T1 pós-contraste demonstram volumosa lesão cística pancreática com componente sólido mural que apresenta realce pelo meio de contraste. Associa-se dilatação significativa do ducto principal.`
                    }
               ]
            },
            {
                "id":7,
                "nome": "Capítulo 12",
                "conteudos":[
                    {
                        "tipo": "video",
                        "label": "1",
                        "conteudo": "https://player.vimeo.com/video/700839913",
                        "legenda": "Deglutograma. Observa-se episódio de aspiração com resíduo durante a deglutição da consistência líquida engrossada. Classificação Robbins 8."
                    },
                    {
                        "tipo": "video",
                        "label": "2",
                        "conteudo": "https://player.vimeo.com/video/700839941",
                        "legenda": "Seriografia esôfago-estômago-duodeno. Discinesia esofágica caracterizada por ondas de contração terciária, que determinam retardo na progressão do meio de contraste."
                    },

                    {
                        "tipo": "video",
                        "label": "3A",
                        "conteudo": "https://player.vimeo.com/video/700839980",
                        "legenda": "Seriografia esôfago-estômago-duodeno. Esôfago acotovelado e discinético, com esvaziamento lento, apresentando contraste residual persistente. Volumoso saco herniário intratorácico mas com bom esvaziamento na posição ortostática."
                    },
                    {
                        "tipo": "video",
                        "label": "3B",
                        "conteudo": "https://player.vimeo.com/video/700840009",
                        "legenda": "Seriografia esôfago-estômago-duodeno. Esôfago acotovelado e discinético, com esvaziamento lento, apresentando contraste residual persistente. Volumoso saco herniário intratorácico mas com bom esvaziamento na posição ortostática."
                    },
                    {
                        "tipo": "video",
                        "label": "3C",
                        "conteudo": "https://player.vimeo.com/video/700840042",
                        "legenda": "Seriografia esôfago-estômago-duodeno. Esôfago acotovelado e discinético, com esvaziamento lento, apresentando contraste residual persistente. Volumoso saco herniário intratorácico mas com bom esvaziamento na posição ortostática."
                    },

                    {
                        "tipo": "video",
                        "label": "4",
                        "conteudo": "https://player.vimeo.com/video/700840085",
                        "legenda": "Proctografia evacuatória com três contrastes (reto, delgado e vagina) demonstrando evacuação em tempo fisiológico, com boa abertura do canal anal."
                    },
                    {
                        "tipo": "video",
                        "label": "5",
                        "conteudo": "https://player.vimeo.com/video/700840114",
                        "legenda": "Enterorressonância magnética, aquisição dinâmica no plano coronal. Peristaltismo normal das alças delgadas."
                    },
                    {
                        "tipo": "imagem",
                        "label": "6A",
                        "conteudo": url + '9786555767834-S03C012QR006A.png',
                        "legenda": "Modelos de laudos de enterografia."
                    },
                    {
                        "tipo": "imagem",
                        "label": "6B",
                        "conteudo": url + '9786555767834-S03C012QR006B.png',
                        "legenda": "Modelos de laudos de enterografia."
                    },

                ]},
                {
                    "id":8,
                    "nome":"Capítulo 14",
                    "conteudos":[
                        {
                            "tipo": "imagem",
                            "label": "1",
                            "conteudo": url + '9786555767834-S03C014QR001 final.png',
                            "legenda": "Paciente de 27 anos com mutação no gene TP53, sexo feminino, assintomática. A, B, C e D: imagens de ressonância magnética de corpo, mostrando lesão no sistema nervoso central (STIR, TIWI, DWI com baixo b e DWI com alto b, respectivamente). E e F: ressonância magnética de crânio caracterizando melhor a lesão, que se tratava de um glioma de baixo grau."
                         },
                         {
                            "tipo": "imagem",
                            "label": "2",
                            "conteudo": url+'9786555767834-S03C014QR002 final.png',
                            "legenda":"Sequências STIR de RMC em paciente de 53 anos em acompanhamento de TNE pancreático G2. A: início do tratamento com imunossupressor; B: exame feito 1 ano e 6 meses após o primeiro demonstrando redução da lesão. Avaliação por imagem dos TNE é um desafio por ser um grupo heterogêneo de neoplasias que se caracterizam por diferentes graus de expressão de receptores de somatostatina."
                         },
                         {
                             "tipo": "imagem",
                             "label": "3",
                            "conteudo": url+"9786555767834-S03C014QR003 final.png",
                            "legenda":"Sequências de RMC (A e B) em paciente de 52 anos, do sexo feminino, em acompanhamento de TNE de cauda pancreática. A: imagem DWI mostrando o tumor primário (seta branca) e também metástases hepáticas (setas pretas); B: imagem SCIN; C: 68Ga-DOTATATE PET/CT."
                         },
                         {
                            "tipo": "imagem",
                            "label": "4",
                            "conteudo": url+'9786555767834-S03C014QR004 final.png',
                            "legenda":"Múltiplos exames de imagem em paciente de 40 anos, do sexo masculino, em acompanhamento de TNE pancreático G3 metastático. A: 68Ga-DOTATATE PET/CT; B: 18FDG PET/CT; C: DWI em RMC; D: imagem RMC de controle pós-embolização."
                         },
                         {
                            "tipo": "imagem",
                            "label": "5",
                            "conteudo": url+"9786555767834-S03C014QR005 final.png",
                            "legenda":"Sequências STIR de RMC em paciente de 31 anos, do sexo masculino, em acompanhamento de TNE de cólon G2, com metástase hepática (setas). A: início do tratamento com análogo da somatostatina; B: exame feito 1 ano após o primeiro demonstrando estabilidade da lesão."
                         }
                    ]
                }
        ]
    },
    {   "id": 4,
        "titulo": "Seção 4 –  Medicina interna – vascular",
        "cor": "#7DA5C9",
        "secondaryColor": "#27547A",
        "capitulos": [
            {
                "id": 50,
                "nome": "Capítulo 7",              
                "conteudos": [
                    {
                        "tipo": "imagem",
                        "label": "1",
                        "conteudo": url+'9786555767834-S04C007QR001.png',
                        "legenda": `Ultrassonografia com administração intrave­nosa de agente de contraste ­microbolhas. A: saco aneurismático com a prótese aortobiilíaca antes da administração de contraste microbolhas. B: contrastação das artérias ilíacas (ponta de seta) e do saco aneurismático, caracterizando <i>endoleak</i> (seta).`
                    }                  

                ]
            },
        ]
    },
    {
        "id": 5,
        "titulo": "Seção 5 –  Medicina interna – genitourinário",
        "cor": "#71368f",
        "secondaryColor": "#27547A",
        "capitulos": [
            {
                "id": 68,
                "nome": "Capítulo 1",              
                "conteudos": [
                    {
                        "tipo": "imagem",
                        "label": "1",
                        "conteudo": url+'9786555767834-S05C001QR001.png',
                        "legenda": `Representação dos espaços pararrenais e da anatomia retroperitoneal. A seta azul escura demonstra o espaço pararrenal anterior, contendo o pâncreas, o cólon ascendente e o descendente.  A seta verde evidencia o espaço perirrenal, composto por ambos os rins, a aorta e a veia cava inferior. A seta azul clara demonstra o espaço parrenal posterior, delimitado ao nível das vértebras lombares.`
                    }                                      

                ]
            },
            {
                "id": 69,
                "nome": "Capítulo 12",              
                "conteudos": [
                    {
                        "tipo": "imagem",
                        "label": "1",
                        "conteudo": url+'9786555767834-S05C012QR001.png',
                        "legenda": `Imagem esquemática demonstrando o sistema de classificação dos leiomiomas.`
                    }                                      

                ]
            },
        ]
    },
    {
        "id":8,
        "titulo": "Seção 8 – Musculoesquelético",
        "cor": "#5EBC5E",
        "secondaryColor": "#627A62",
        "capitulos": [
            {
                "id":9,
                "nome":"Capítulo 6",
                "conteudos":[
                    {
                        "tipo": "video",
                        "label": "1",
                        "conteudo": "https://player.vimeo.com/video/700840153",
                        "legenda":`Manobra dinâmica à ultrassonografia, evidenciando síndrome do dedo "em gatilho".`
                     }
                ]},
                {
                  "id":10,
                  "nome":"Capítulo 8",
                  "conteudos":[
                      {
                        "tipo": "imagem",
                        "label": "1",
                        "conteudo": url+ '9786555767834-S08C008QR001.png',
                        "legenda":"Radiografia de bacia anteroposterior."                 
                      },{
                        "tipo": "imagem",
                        "label": "2",
                        "conteudo": url + '9786555767834-S08C008QR002.png',
                        "legenda":"Incidência <i>inlet</i>: raio central é angulado caudal em 60° para avaliar se há deslocamento posterior do anel pélvico ou abertura da sínfise púbica."                 
                     
                      },
                      {
                        "tipo": "imagem",
                        "label": "3",
                        "conteudo": url +'9786555767834-S08C008QR003.png',
                        "legenda":"Incidência <i>outlet</i>: o raio central é angulado 45° cefálico e define se há deslocamento cranial ou caudal do anel pélvico."                 
                     
                      },
                      {
                        "tipo": "imagem",
                        "label": "4",
                        "conteudo": url+'9786555767834-S08C008QR004.png',
                        "legenda":"Incidência alar (do lado esquerdo) e obturatriz (lado direito): a linha branca representa a linha ilioisquiática ou coluna posterior do acetábulo e a linha preta, iliopectínea ou iliopúbica, corresponde à coluna anterior do acetábulo."                 
                     
                      }
                  ]
                },{
                   "id":11,
                   "nome":"Capítulo 11",
                   "conteudos":[
                       {
                        "tipo": "imagem",
                        "label": "1",
                        "conteudo": url+ '9786555767834-S08C011QR001 final.png',
                        "legenda": "Radiografias em AP. <i>Index minus</i> (A), <i>index plus minus</i> (B) e <i>index plus</i> (C)."
                       },
                       {
                        "tipo": "imagem",
                        "label": "2",
                        "conteudo": url+"9786555767834-S08C011QR002 final.png",
                        "legenda": "Radiografias em AP. Pé egípcio (A), pé grego (B) e pé polinésico ou quadrado (C)."
                       },
                       {
                        "tipo": "video",
                        "label": "3",
                        "conteudo": "https://player.vimeo.com/video/700840185",
                        "legenda": "Ultrassonografia dinâmica para diagnóstico de neuroma. Transdutor no eixo coronal da face plantar do antepé no plano dos espaços intermetatársicos. Realizada a compressão manual latero-lateral da cabeça dos metatarsos com a superficialização do neuroma (seta)."
                       },

                   ]
                },
                {
                    "id":110,
                    "nome":"Capítulo 14",
                    "conteudos":[
                        {
                         "tipo": "imagem",
                         "label": "1",
                         "conteudo": url + '9786555767834-S08C015QR001.png',
                         "legenda": "Esquema de margens, matriz e reações periosteais dos tumores ósseos."
                        },                      
 
                    ]
                 },
                {
                    "id":12,
                    "nome":"Capítulo 17",
                    "conteudos":[
                        {
                            "tipo": "imagem",
                            "label": "1",
                            "conteudo": url+'9786555767834-S08C017QR001.png',
                            "legenda": "Doença de Blount. Radiografia panorâmica de membros inferiores com deformidade em varo da metáfise proximal da tíbia bilateral com proeminência óssea e leve hipoplasia da região metaepifisária medial."
                        },
                        {
                            "tipo": "imagem",
                            "label": "2",
                            "conteudo": url+ "9786555767834-S08C017QR002 final.png",
                            "legenda": "Doença de Scheurmann. Radiografias panorâmicas da coluna toracolobar evidenciam grande acentuação da cifose torácica, com redução da altura anterior de alguns corpos vertebrais."
                           },
                           {
                            "tipo": "imagem",
                            "label": "3",
                            "conteudo": url+ "9786555767834-S08C017QR003 final.png",
                            "legenda": "Osteopetrose. Imagens de tomografia computadorizada, no plano axial. Observam-se aumento da densidade das estruturas ósseas na base do crânio com redução da diferenciação entre a cortical e a medular óssea, alterações compatíveis com osteopetrose."
                           },  
                           {
                            "tipo": "imagem",
                            "label": "4",
                            "conteudo": url +"9786555767834-S08C017QR004 final.png",
                            "legenda": "Acondroplasia. Radiografias de perna esquerda (A), braço direito (B) e bacia (C). Observam-se: alteração morfológica nas regiões metaepifisárias proximais e distais dos ossos da perna, com redução do comprimento dos ossos da perna (A); encurtamento da diáfise umeral e alargamento das metáfises umerais (B) e sinais de acondroplasia no quadril (C) – alargamento das asas ilíacas e fêmures proximais e redução das distâncias interpediculares lombares baixas."
                           },
                           {
                            "tipo": "imagem",
                            "label": "5",
                            "conteudo": url + "9786555767834-S08C017QR005.png",
                            "legenda": "Osteopoiquilose. Imagem de tomografia computadorizada, no plano axial, mostrando múltiplos diminutos focos escleróticos no trabeculado ósseo das cabeças umerais e escápulas."
                           } ,

                    ]
                }
        ]
    },

    {
        "id":11,
        "titulo": "Seção 11 – Ultrassonografia",
        "cor": "#91388A",
        "secondaryColor": "#7A5477",
        "capitulos": [
            {
                "id":13,
                "nome":"Capítulo 6",
                "conteudos":[
                    {
                        "tipo": "video",
                        "label": "1",
                        "conteudo": "https://player.vimeo.com/video/700840205",
                        "legenda": "Canal inguinal sem herniações durante manobra de Valsalva."
                       },
                       {
                        "tipo": "video",
                        "label": "2",
                        "conteudo": "https://player.vimeo.com/video/700840231",
                        "legenda": "Hérnia femoral à direita durante manobra de Valsalva."
                       },
                ]
            },
            {
                "id":14,
                "nome":"Capítulo 7",
                "conteudos":[
                    {
                        "tipo": "imagem",
                        "label": "1",
                        "conteudo": url + "9786555767834-S11C007QR001 final.png",
                        "legenda": "Complicação pós-cateterismo. A: fístula arteriovenosa entre a artéria femoral superficial direita (AFSD) e a veia femoral comum direita (VFCD), com colo aparente de 0,3 cm (seta); B e C: nota-se fluxo turbilhonado."
                    },
                ]
            },

        ]
    }
]

export default secoes;